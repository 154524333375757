import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { Image } from '../../components/Image/Image';
import { columnSx, galleryContainerSx } from './Gallery.style';
import { Button } from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../constants/paths';
import { buttonSx } from '../../styles/Global.style';
import { Loading } from '../../components/Loading/Loading';
import { useImgLoading } from '../../hooks/useImgLoading/useImgLoading';

export const Gallery = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLoading, onImgLoad } = useImgLoading();

  return (
    <>
      {isLoading && <Loading />}

      <Box sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        <ContentCard
          title={Copy.generate('Gallery.title')}
          heading={Copy.inject('Gallery.heading', {
            events: dynamicTextColor(
              'our events.',
              theme.palette.quaternary.main,
            ),
          })}
          isColumn
          isTop
        >
          <Box component="div" sx={galleryContainerSx}>
            <Box component="div" sx={columnSx}>
              {[...Array(10)].map((_, i) => (
                <Image
                  key={i}
                  img={`assets/img/gallery/${i}.webp`}
                  alt=""
                  onLoad={onImgLoad}
                />
              ))}
            </Box>

            <Box component="div" sx={columnSx}>
              {[...Array(10)].map((_, i) => (
                <Image
                  key={i}
                  img={`assets/img/gallery/${i + 10}.webp`}
                  alt=""
                />
              ))}
            </Box>
          </Box>
        </ContentCard>

        <ContentCard
          heading={Copy.inject('Gallery.competition.heading', {
            big: dynamicTextColor(
              'The Next Big Thing',
              theme.palette.quaternary.main,
            ),
          })}
          isSecondaryCard
          isColumn
        >
          <Box>
            <Button
              text={Copy.generate('Buttons.competition')}
              onClick={() => navigate(APP_PATHS.competition)}
              sx={buttonSx(theme)}
            />
          </Box>
        </ContentCard>
      </Box>
    </>
  );
};
