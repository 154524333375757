import { useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';

export const About = () => {
  const theme = useTheme();
  const quaternary = theme.palette.quaternary.main;

  return (
    <>
      <ContentCard
        title={Copy.generate('About.journey.title')}
        heading={Copy.inject('About.journey.heading', {
          journey: dynamicTextColor('journey', quaternary),
        })}
        content={Copy.generate('About.journey.content')}
        isColumn
        isTop
      />

      <ContentCard
        title={Copy.generate('About.mission.title')}
        heading={Copy.inject('About.mission.heading', {
          mission: dynamicTextColor('mission.', quaternary),
        })}
        content={Copy.generate('About.mission.content')}
        isColumn
        isSecondaryCard
      />
    </>
  );
};
