import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { Home } from '../pages/Home/Home';
import { APP_PATHS } from '../constants/paths';
import { About } from '../pages/About/About';
import { Contact } from '../pages/Contact/Contact';
import { Competition } from '../pages/Competition/Competition';
import { Artists } from '../pages/Artists/Artists';
import { Gallery } from '../pages/Gallery/Gallery';

export const Routes = () => (
  <Switch>
    <Route path={APP_PATHS.home} element={<Home />} />
    <Route path={APP_PATHS.about} element={<About />} />
    <Route path={APP_PATHS.contact} element={<Contact />} />
    <Route path={APP_PATHS.competition} element={<Competition />} />
    <Route path={APP_PATHS.artists} element={<Artists />} />
    <Route path={APP_PATHS.gallery} element={<Gallery />} />
    <Route path="*" element={<Navigate to={APP_PATHS.home} replace />} />
  </Switch>
);
