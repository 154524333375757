import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { buttonSx } from '../../styles/Global.style';
import { Button } from '../../components/Button/Button';

export const Competition = () => {
  const theme = useTheme();

  return (
    <>
      <ContentCard
        title={Copy.generate('Competition.title')}
        heading={Copy.inject('Competition.heading', {
          perform: dynamicTextColor(
            'perform in great venues.',
            theme.palette.quaternary.main,
          ),
        })}
        isColumn
        isTop
      />

      <ContentCard
        title={Copy.generate('Competition.2024.title')}
        heading={Copy.generate('Competition.2024.heading')}
        content={Copy.generate('Competition.2024.content')}
        isSecondaryCard
        isColumn
      >
        <Box>
          <Button
            text={Copy.generate('Buttons.register')}
            onClick={() =>
              (window.location.href = Copy.generate('Global.registerLink'))
            }
            sx={buttonSx(theme)}
          />
        </Box>
      </ContentCard>

      <ContentCard
        heading={Copy.inject('Competition.quotes.1.title', {
          star: dynamicTextColor(
            'star in their own right!',
            theme.palette.tertiary.main,
          ),
        })}
        content={Copy.generate('Competition.quotes.1.content')}
        isColumn
        isQuote
      />
    </>
  );
};
