import { useState } from 'react';
import { ContactUsForm } from '../../components/ContactUsForm/ContactUsForm';
import { Success } from '../../components/Success/Success';
import { Copy } from '../../copy/Copy';
import { GetInTouch } from '../../components/GetInTouch/GetInTouch';
import { Box, useTheme } from '@mui/material';
import {
  contactContainerSx,
  contactUsFormContainerSx,
  getInTouchContainerSx,
} from './Contact.style';

export const Contact = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const theme = useTheme();

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  return (
    <Box component="section" sx={contactContainerSx(theme)}>
      <Box component="section" sx={getInTouchContainerSx(theme)}>
        <GetInTouch />
      </Box>

      <Box component="section" sx={contactUsFormContainerSx}>
        {!isSubmitted && <ContactUsForm handleSubmit={handleSubmit} />}

        {isSubmitted && (
          <Success
            title={Copy.generate('Contact.success.title')}
            text={Copy.generate('Contact.success.text')}
          />
        )}
      </Box>
    </Box>
  );
};
