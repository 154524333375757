import { Box, useTheme } from '@mui/material';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import { Tagline } from '../Tagline/Tagline';
import { Logo } from '../../icons/Logo/Logo';
import { marginBottom16Sx } from '../../styles/Global.style';
import { Text } from '../Text/Text';
import { copyRightSx, footerContainerSx } from './Footer.style';
import { Copy } from '../../copy/Copy';

export const Footer = () => {
  const theme = useTheme();

  return (
    <Box component="footer" sx={footerContainerSx}>
      <Box component="div" sx={marginBottom16Sx}>
        <Logo width={100} height={55} />
      </Box>

      <SocialLinks sx={marginBottom16Sx} />

      <Tagline sx={marginBottom16Sx} />

      <Text
        text={Copy.generate('Global.copyright')}
        color={theme.palette.secondary.main}
        sx={copyRightSx}
      />
    </Box>
  );
};
