import { MailOutline, Instagram, Facebook, YouTube } from '@mui/icons-material';
import { IconLink } from '../IconLink/IconLink';
import { Box, IconButton, useTheme } from '@mui/material';
import { tiktokSx } from './SocialLinks.style';
import { Copy } from '../../copy/Copy';
import { flexCenterSx, iconLinkSx } from '../../styles/Global.style';
import { TikTok } from '../../icons/TikTok/TikTok';

export const SocialLinks = ({ sx }) => {
  const theme = useTheme();

  return (
    <Box sx={{ ...flexCenterSx, ...sx }}>
      <IconLink
        icon={Instagram}
        href={Copy.generate('Socials.instagram')}
        sx={iconLinkSx(theme)}
        aria="Instagram link"
      />

      <IconButton
        onClick={() => window.open(Copy.generate('Socials.tiktok'))}
        sx={tiktokSx}
        aria-label="Tiktok link"
      >
        <TikTok sx={iconLinkSx(theme)} />
      </IconButton>

      <IconLink
        icon={Facebook}
        href={Copy.generate('Socials.facebook')}
        sx={iconLinkSx(theme)}
        aria="Facebook link"
      />

      <IconLink
        icon={YouTube}
        href={Copy.generate('Socials.youtube')}
        sx={iconLinkSx(theme)}
        aria="Youtube link"
      />

      <IconLink
        icon={MailOutline}
        href={Copy.generate('Global.emailLink')}
        sx={iconLinkSx(theme)}
        aria="email link"
      />
    </Box>
  );
};
