import { createTheme } from '@mui/material/styles';
import { typography } from './typography';
import { breakpoints } from './breakpoints';
import { components } from './components';

const darkTheme = createTheme({
  typography,
  components,
  breakpoints,
  palette: {
    background: {
      default: '#121212',
      secondary: '#181818',
    },
    primary: {
      main: '#121212',
    },
    secondary: {
      main: '#ffffff',
    },
    tertiary: {
      main: '#BC13FE',
    },
    quaternary: {
      main: '#d4af37',
    },
    default: {
      main: '#ffffff',
    },
    error: {
      main: '#d32f2f',
    },
    colors: {
      dark: '#121212',
      light: '#ffffff',
    },
  },
});

export default darkTheme;
