import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useTheme } from '@mui/material';

export const Photo = ({ size = 'large' }) => {
  const theme = useTheme();

  return (
    <PhotoCameraIcon
      fontSize={size}
      sx={{ color: theme.palette.tertiary.main }}
    />
  );
};
