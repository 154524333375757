import { Box, useTheme } from '@mui/material';
import { Button } from '../../components/Button/Button';
import { Copy } from '../../copy/Copy';
import { buttonSx } from '../../styles/Global.style';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { ContactUsButton } from '../../components/ContactUsButton/ContactUsButton';
import { ContentCard } from '../../components/ContentCard/ContentCard';
// import { PromoVideo } from '../../components/PromoVideo/PromoVideo';
import { FeatureList } from '../../components/FeatureList/FeatureList';
import { splashImgDimensions } from './Home.style';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../constants/paths';
import { Loading } from '../../components/Loading/Loading';
import { useImgLoading } from '../../hooks/useImgLoading/useImgLoading';

export const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLoading, onImgLoad } = useImgLoading();

  return (
    <>
      {isLoading && <Loading />}

      <Box sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        <ContentCard
          img="/assets/img/splash.webp"
          imgDimensions={splashImgDimensions}
          onImgLoad={onImgLoad}
          title={Copy.generate('Home.splash.title')}
          heading={Copy.inject('Home.splash.heading', {
            yourMind: dynamicTextColor(
              'across Scotland.',
              theme.palette.quaternary.main,
            ),
          })}
          content={Copy.generate('Home.splash.content')}
          isTop
        >
          <ContactUsButton />
        </ContentCard>

        <ContentCard
          title={Copy.generate('Home.features.title')}
          heading={Copy.inject('Home.features.heading', {
            music: dynamicTextColor(
              'their own music.',
              theme.palette.quaternary.main,
            ),
          })}
          isSecondaryCard
          isColumn
        >
          <FeatureList />
        </ContentCard>

        {/* <ContentCard
        title={Copy.generate('Home.video.title')}
        heading={Copy.generate('Home.video.heading')}
        video={<PromoVideo />}
        isSecondaryCard
        isReversed
      /> */}

        <ContentCard
          img="/assets/img/artist.webp"
          title={Copy.generate('Home.artists.title')}
          heading={Copy.inject('Home.artists.heading', {
            world: dynamicTextColor(
              'world class',
              theme.palette.quaternary.main,
            ),
          })}
          content={Copy.generate('Home.artists.content')}
        >
          <Box>
            <Button
              text={Copy.generate('Buttons.artists')}
              onClick={() => navigate(APP_PATHS.artists)}
              sx={buttonSx(theme)}
            />
          </Box>
        </ContentCard>

        <ContentCard
          img="/assets/img/comp.webp"
          title={Copy.generate('Home.competition.title')}
          heading={Copy.inject('Home.competition.heading', {
            discovered: dynamicTextColor(
              'discovered',
              theme.palette.quaternary.main,
            ),
          })}
          content={Copy.generate('Home.competition.content')}
          isSecondaryCard
          isReversed
        >
          <Box>
            <Button
              text={Copy.generate('Buttons.competition')}
              onClick={() => navigate(APP_PATHS.competition)}
              sx={buttonSx(theme)}
            />
          </Box>
        </ContentCard>

        {/*
      <ContentCard
        heading={Copy.inject('Home.quotes.1.title', {
          takePart: dynamicTextColor(
            'I can take part.',
            theme.palette.tertiary.main,
          ),
        })}
        content={Copy.generate('Home.quotes.1.content')}
        isColumn
        isQuote
      />*/}

        <ContentCard
          heading={Copy.inject('Home.contactUs.heading', {
            weBring: dynamicTextColor(
              "We'll bring the support.",
              theme.palette.quaternary.main,
            ),
          })}
          isColumn
        >
          <ContactUsButton />
        </ContentCard>
      </Box>
    </>
  );
};
