import { useTheme, Box } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { ContactUsButton } from '../../components/ContactUsButton/ContactUsButton';
import { Loading } from '../../components/Loading/Loading';
import { useImgLoading } from '../../hooks/useImgLoading/useImgLoading';

export const Artists = () => {
  const theme = useTheme();
  const { isLoading, onImgLoad } = useImgLoading();

  return (
    <>
      {isLoading && <Loading />}

      <Box sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        <ContentCard
          title={Copy.generate('Artists.title')}
          heading={Copy.inject('Artists.heading', {
            all: dynamicTextColor(
              'all platforms.',
              theme.palette.quaternary.main,
            ),
          })}
          isColumn
          isTop
        />

        <ContentCard
          img="/assets/img/scaz.webp"
          onImgLoad={onImgLoad}
          heading={Copy.generate('Artists.scaz.name')}
          content={Copy.generate('Artists.scaz.bio')}
          isSecondaryCard
          isReversed
        />

        <ContentCard
          img="/assets/img/orla.webp"
          heading={Copy.generate('Artists.orla.name')}
          content={Copy.generate('Artists.orla.bio')}
        />

        <ContentCard
          img="/assets/img/lucy.webp"
          heading={Copy.generate('Artists.lucy.name')}
          content={Copy.generate('Artists.lucy.bio')}
          isSecondaryCard
          isReversed
        />

        <ContentCard
          heading={Copy.inject('Artists.contactUs.heading', {
            contact: dynamicTextColor(
              'contact us.',
              theme.palette.quaternary.main,
            ),
          })}
          isColumn
        >
          <ContactUsButton />
        </ContentCard>
      </Box>
    </>
  );
};
